import styled, { css, } from 'react-emotion';

import { hex2rgba, } from '@ezugi/utils';
import { theme, } from '@ezugi/bootstrap';

const { palette, } = theme;

export const Container = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  writing-mode: vertical-lr;
  user-select: none;
`;

export const parentClassName = css`
  align-items: center;
  width: 22px;
`;

export const parentBLClassName = css`
  align-items: center;
  width: 22px;
  background: ${hex2rgba(palette.background, 0.87)};
`;
