import {
  backAndLayEpics,
  betsEpic,
  betsReducer,
  cardsEpic,
  cardsReducer,
  myBetsEpic,
  payoutsEpic,
  socketEpic,
  statisticsReducer,
} from './store/index';
import { gameResultsClassName, resultMessageClassName, } from './components/ResultDisplay/style';
import { parentBLClassName, parentClassName, } from './components/RightSideDrawerToggler/style';
import RoundStepInfo from './components/RoundStepInfo';

import { GAME_BOARD_PERCENTAGE_HEIGHT as DEFAULT_GAME_BOARD_PERCENTAGE_HEIGHT, } from './views/GameBoard/style';
// eslint-disable-next-line max-len
import { GAME_BOARD_PERCENTAGE_HEIGHT as BACK_AND_LAY_GAME_BOARD_PERCENTAGE_HEIGHT, } from './views/GameBoard/BackAndLay/style';

import handleOperatorError from './store/epics/socket/handleOperatorError';
import { isBackAndLayOnURL, } from './utils';

const noop = () => Promise.resolve({ default: () => null, });
const GAME_BOARD_PERCENTAGE_HEIGHT = isBackAndLayOnURL
  ? BACK_AND_LAY_GAME_BOARD_PERCENTAGE_HEIGHT : DEFAULT_GAME_BOARD_PERCENTAGE_HEIGHT;

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard'),
    Payouts: () => import('./components/Payouts'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    RightSideDrawerContent: () => import('./components/RightSideDrawerContent'),
    GameInfo: () => import('./components/GameInfo'),
    ResultDisplay: () => import('./components/ResultDisplay'),
    Statistics: () => import('./components/Statistics'),
    VideoOverlay: () => import('./components/VideoOverlay'),
    MyBets: () => import('./components/MyBets'),
    ProgressBar: noop,
    ...(isBackAndLayOnURL ? {
      WelcomeDialog: noop,
      MobileToolbar: noop,
      GameTools: noop,
      ProgressBar: noop,
      CallBetsToolbar: noop,
      GameBoard: () => import('./views/GameBoard/BackAndLay'),
      MyBets: () => import('./components/MyBets/BackAndLay'),
      RightSideDrawerContent: () => import('./components/RightSideDrawerContent/BackAndLay'),
      VideoOverlay: () => import('./components/VideoOverlay/BackAndLay'),
      Payouts: () => import('./components/Payouts/BackAndLay'),
      ResultDisplay: () => import('./components/ResultDisplay/BackAndLay'),
    } : {}),
  },
  store: {
    epics: [ socketEpic, cardsEpic, ].concat(isBackAndLayOnURL ? [ backAndLayEpics, myBetsEpic, ]
      : [ betsEpic, payoutsEpic, myBetsEpic, ]),
    reducers: { cards: cardsReducer, bets: betsReducer, statistics: statisticsReducer, },
  },
  props: {
    GameResults: { hideWinnerList: true, className: gameResultsClassName, },
    GameRightSideDrawer: { toggler: {
      component: () => import('./components/RightSideDrawerToggler'),
      parentClassName: isBackAndLayOnURL ? parentBLClassName : parentClassName,
    },
    style: { height: '50%', top: `-${GAME_BOARD_PERCENTAGE_HEIGHT / 2}%`, }, },
    ResultMessage: {
      className: resultMessageClassName,
    },
    BetToolbar: {
      style: {
        zIndex: 1,
      },
      children: RoundStepInfo,
    },
  },
  gameBoardHeight: 22,
  landscapeToolbarOrientation: isBackAndLayOnURL ? undefined : 'vertical',
  desktopBoardToggle: false,
  handleOperatorError,
});
