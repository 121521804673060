import { BL_TOTALS, } from '../../../../constants/BackAndLay/betTypes';

const {
  TOTAL_PLAYER_A_BACK, TOTAL_PLAYER_B_BACK, TOTAL_PLAYER_A_LAY, TOTAL_PLAYER_B_LAY,
} = BL_TOTALS;

export const BL_INITIAL_STATE_TOTALS = {
  totals: {
    // TOTALS FOR BACK BETS
    [TOTAL_PLAYER_A_BACK]: 0,
    [TOTAL_PLAYER_B_BACK]: 0,
    // TOTALS FOR LAY BETS
    [TOTAL_PLAYER_A_LAY]: 0,
    [TOTAL_PLAYER_B_LAY]: 0,
  },
};
