import { handleActions, } from 'redux-actions';
import { dropLast, } from 'ramda';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { getTotalBet, } from '../../epics/bets/utils';
import totalsActions from '../../actions/totals';
import myBetsActions from '../../actions/myBets';

import * as BET_TYPES from '../../../constants/betTypes';
import { isBackAndLayOnURL, } from '../../../utils';
import { BL_INITIAL_STATE_TOTALS, } from './BackAndLay';

const {
  TOTALS: { TOTAL_PLAYER_A, TOTAL_PLAYER_B, TOTAL_ONE_PAIR, TOTAL_STRAIGHT, TOTAL_FLUSH, TOTAL_STRAIGHT_FLUSH,
    TOTAL_THREE_OF_A_KIND, },
} = BET_TYPES;


const { betActions, } = bootstrapActions;
const { totals, } = totalsActions;

export const INITIAL_STATE = {
  current: {},
  totalBet: 0,
};

const INITIAL_STATE_MY_BETS = {
  myBets: {},
};

const INITIAL_STATE_TOTALS = isBackAndLayOnURL ? BL_INITIAL_STATE_TOTALS : {
  totals: {
    // TOTALS FOR MAIN BETS
    [TOTAL_PLAYER_A]: 0,
    [TOTAL_PLAYER_B]: 0,
    // TOTALS FOR SIDE BETS
    [TOTAL_ONE_PAIR]: 0,
    [TOTAL_STRAIGHT]: 0,
    [TOTAL_FLUSH]: 0,
    [TOTAL_STRAIGHT_FLUSH]: 0,
    [TOTAL_THREE_OF_A_KIND]: 0,
  },
};

INITIAL_STATE.history = [ { ...INITIAL_STATE, }, ];

export default handleActions(
  {
    [betActions.bet.set]: ({ history, ...state }, { payload: { type, value, ...rest }, }) => {
      const current = {
        ...state.current,
        [type]: { value: ((state.current[type] || {}).value || 0) + value, ...rest, },
      };
      const t = getTotalBet(current);

      return {
        ...state,
        current,
        history: [ ...history, { ...state, current, totalBet: t, }, ],
      };
    },
    [betActions.bet.reset]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      ...INITIAL_STATE_TOTALS,
      ...INITIAL_STATE_MY_BETS,
    }),
    [betActions.bet.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      history: [ ...state.history, INITIAL_STATE, ],
    }),
    [betActions.bet.cache]: (state, { payload, }) => ({
      ...state,
      last: payload,
    }),
    [betActions.bet.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
      history: [ ...state.history, payload, ],
    }),
    [betActions.history.reset]: (state) => ({
      ...state,
      history: [ { ...INITIAL_STATE, }, ],
    }),
    [betActions.history.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [betActions.totalBet.set]: (state, { payload: { value = INITIAL_STATE.totalBet, }, }) => ({
      ...state,
      totalBet: value,
    }),
    /* totals for each type of BET */
    [totals.set]: (state, { payload, }) => ({
      ...state,
      ...{
        totals: {
          ...payload,
        },
      },
    }),
    [totals.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE_TOTALS,
    }),
    [myBetsActions.myBets.push]: ({ myBets, ...state }, { payload, }) => ({
      ...state,
      myBets: { ...myBets, ...payload, },
    }),
    [myBetsActions.myBets.pop]: ({ myBets, ...state }) => ({
      ...state,
      myBets: dropLast(1, myBets),
    }),
    [myBetsActions.myBets.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE_MY_BETS,
    }),
  },
  {
    ...INITIAL_STATE,
    ...INITIAL_STATE_TOTALS,
    ...INITIAL_STATE_MY_BETS,
  }
);
