import { map, pluck, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { actions as bootstrapActions, selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import { formatPayouts, getComputedPayoutsEvolution, } from './utils';
import payoutsActions from '../../actions/payouts';
import { currentPayoutsProgressSelector, } from '../../selectors/payouts';

const {
  configActions: { config, },
} = bootstrapActions;
const {
  formatSelector,
} = bootstrapSelectors;

function payoutsEpic(action$, state$) {
  return action$.pipe(
    ofType(payoutsActions.payouts.set),
    pluck('payload'),
    map(({ payouts: nextPayoutsValues = {}, }) => {
      const activePayoutsValue = currentPayoutsProgressSelector(state$.value);
      const { payoutFormat: PAYOUT_FORMAT, } = formatSelector(state$.value);

      return config.update({
        payouts: formatPayouts(nextPayoutsValues, PAYOUT_FORMAT),
        payoutsProgress: {
          current: nextPayoutsValues,
          prev: activePayoutsValue,
          status: getComputedPayoutsEvolution(activePayoutsValue, nextPayoutsValues),
        },
      });
    }),
  );
}

export default payoutsEpic;
