import styled, { css, keyframes, } from 'react-emotion';
import tinycolor from 'tinycolor2';
import { theme, } from '@ezugi/bootstrap';

import * as BET_TYPES from '../../constants/betTypes';
import { OPACITY_HIGH, } from '../../constants/colors';
import { glowingPulse, } from '../../components/SideBet/style';

const { palette, } = theme;

const {
  MAIN_BETS: { PLAYER_A, PLAYER_B, },
} = BET_TYPES;
const SECTION_HEADER_HEIGHT = 30;
export const GAME_BOARD_PERCENTAGE_HEIGHT = 22;

const highlightKeyframe = keyframes`
  from {
    fill-opacity: 0.65;
  }
  to {
    fill-opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${GAME_BOARD_PERCENTAGE_HEIGHT}%;
  bottom: 0;
  left: 0;
  pointer-events: all;
  user-select: none;
  background: transparent;
  transform: translateY(0);
  transition: transform 0.2s linear;

  /* no-winning-mainbets/no-winning-sidebets added to keep css valid when no winning main/side bets */
  ${({ winningMainBets = [], }) => winningMainBets.map((bet) => `#bet-${bet}`).join(',') || 'no-winning-mainbets'} {
    .highlight {
      animation: ${highlightKeyframe} 0.35s 0.1s infinite alternate ease-in-out;
    }
  }

  ${({ winningSideBets = [], }) => winningSideBets.map((bet) => `.highlight-${bet}`).join(',')
  || 'no-winning-sidebets'} {
    animation: ${glowingPulse} 0.35s 0.1s infinite alternate ease-in-out;
  }
`;

const commonFadeStyles = css`
  z-index: 1;
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  transition: opacity 0.27s ease-in;
  opacity: 0;
  margin-left: -5px;
  filter: blur(2px);
  pointer-events: none;
`;

const fadeFrom = tinycolor(palette.primary).setAlpha(0.1).toRgbString();
const fadeTo = tinycolor(palette.primary).setAlpha(1).toRgbString();

export const mobileClassName = css`
  height: 100%;
  @media all and (orientation: portrait) {
    background: ${palette.primary};
    &::after {
      ${commonFadeStyles};
      background: linear-gradient(${fadeFrom}, ${fadeTo});
      bottom: -5px;
    }

    &::before {
      ${commonFadeStyles};
      top: -5px;
      background: linear-gradient(${fadeTo}, ${fadeFrom});
    }
  }
`;

export const mobileBetsClosedClassName = css`
  @media all and (orientation: landscape) {
    height: auto;
  }
`;

export const fadeTopClassName = css`
  &::before {
    opacity: 1;
  }
`;

export const fadeBottomClassName = css`
  &::after {
    opacity: 1;
  }
`;

export const SectionHeader = styled.header`
    height: ${SECTION_HEADER_HEIGHT}px;
    position: relative;
    background: ${palette.primary};
    color: ${palette.foreground};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
`;

export const Section = styled.section`
  height: 100%;
  top: 0;
  display: inline-flex;
  vertical-align: top;
  position: relative;
  transition: flex 0.27s linear, height 0.27s linear;
  transform-origin: bottom center;
  flex: 1;
  background: ${tinycolor(palette.primary).setAlpha(0.9).toString()};

  > svg {
    overflow: visible;
  }

  flex-flow: column nowrap;
`;

export const ScrollingContainer = styled.div`
  width: 100%;
  display: block;
`;

export const mobileScrollingContainerClassName = css`
  @media all and (orientation: landscape) {
    display: flex;
    align-items: center;
  }
`;

export const ScrollingContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row nowrap;

  #bet-icon {
    pointer-events: all;
  }

  [id^='bet-'] {
    &:hover,
    &:active {
      cursor: pointer;
      .highlight {
        fill-opacity: ${OPACITY_HIGH};
      }
    }
  }
`;

export const mainBetsHighlight = css`
  #bet-${PLAYER_A}[data-payout], #bet-${PLAYER_B}[data-payout] {
    .highlight {
      animation: ${highlightKeyframe} infinite .5s 0.1s alternate ease-in-out;
    }
  }
`;

export const inactiveGrid = css`
  cursor: not-allowed;
  pointer-events: none;
`;

export const centerSectionClassName = css`
  align-items: center;
  flex-flow: column nowrap;
  flex: 1.9;
`;

export const mobileCenterSectionClassName = css`
  flex: 1;
`;

export const desktopBetGridClassName = css`
  padding: 0;
  height: calc(100% - ${SECTION_HEADER_HEIGHT}px);
  width: 97%;
`;

export const portraitBetGridClassName = css`
  flex-shrink: 0;
`;

export const progressBarClassName = css`
  width: 100%;
  border-radius: 0;
`;

export const mobileIContainerClassName = css`
  flex-flow: column nowrap;
  padding: 0 5px 5px;

  @media screen and (max-height: 330px) and (orientation: landscape) {
    transform: scale(0.75);
  }
  @media all and (orientation: landscape) {
    padding: 0 32px 0 32px;
    height: auto;

    [data-name="center-section"] {
      background: transparent;
    }
  }
  @media all and (orientation: portrait) {
    padding-bottom: 0;
    height: auto;
    [data-name="center-section"] {
      height: auto;
    }
  }

  [data-name="left-section"] {
    order: 1;
  }
`;

export const mobileIContainerBetsClosedClassName = css`
  perspective: 400px;

  [data-name="left-section"] {
    @media all and (orientation: portrait) {
      display: none;
    }
    @media all and (orientation: landscape) {
      height: 0;
      flex: none;
      overflow: hidden;
    }
  }

  @media all and (orientation: landscape) {
    margin: 0;
    [data-name="center-section"] {
      transform: rotateX(55deg);
    }
  }
  @media screen and (max-height: 330px) and (orientation: landscape) {
    transform: scale(0.75) translateY(15%);
  }
  @media all and (orientation: portrait) {
    padding-bottom: 5px;
  }
`;

export const leftSectionClassName = css`
  flex-flow: row nowrap;
`;

export const sidebetsClassName = css`
  padding: 6px 0;
  height: 64px;
`;

export const sidebetsNMBClassName = css`
  padding: 4px 0;
  height: 42px;
`;
