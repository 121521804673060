import { of, } from 'rxjs';

import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';

import statisticsActions from '../../actions/statistics';
import { gameResultsSelector, } from '../../selectors/game';

const {
  roundActions: { round, },
  gameActions: { game, },
  uiActions: { progressBar, },
  configActions,
} = bootstrapActions;

const { statistics, } = statisticsActions;

export default function handleGameResult(socketMessage, state) {
  const { roundId, timeStamp, WinAmount, GameResults, Statistics, LastWinners, } = socketMessage;

  return of(
    progressBar.reset(),
    game.set({
      gameResults: { ...gameResultsSelector(state), ...GameResults, },
      lastWin: WinAmount,
    }),
    round.set({
      roundStatus: GAME_RESULT,
      roundId,
      winAmount: WinAmount,
      winnerList: LastWinners,
      timestamp: timeStamp,
    }),
    configActions.config.set({ payoutsProgress: {}, }),
    statistics.set(Statistics)
  );
}
