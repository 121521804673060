import { concat, of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { gameResultsSelector, } from '../../selectors/game';
import payoutsActions from '../../actions/payouts';

const {
  gameActions: { game, },
  uiActions: { progressBar, },
  betActions: { history, totalBet, },
} = bootstrapActions;

export default function handlePlaceYourBets(socketMessage, state) {
  const {
    payouts,
  } = socketMessage;

  return concat(of(
    progressBar.reset(),
    payoutsActions.payouts.set({ payouts, }),
    game.set({
      gameResults: { ...gameResultsSelector(state), wonSidebets: [], },
    }),
    history.reset(),
    totalBet.set({ value: 0, }), // each round step is considered a new round
  ));
}
