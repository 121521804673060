import { of, } from 'rxjs';
import {
  pipe, propOr, sum, values,
} from 'ramda';
import { LOADING, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { ls, } from '@ezugi/utils';

import statisticsActions from '../../actions/statistics';
import { PLAYER_A_CARD_HAND, PLAYER_B_CARD_HAND, } from '../../../constants/cards';
import { MAIN_BETS, SIDEBETS, TOTALS, } from '../../../constants/betTypes';
import { BACK_LAY_BETS, BL_TOTALS, } from '../../../constants/BackAndLay/betTypes';
import cardsActions from '../../actions/cards';
import { NO_WELCOME_DIALOG, WELCOME_DIALOG, } from '../../../constants/strings';
import totalsActions from '../../actions/totals';
import payoutsActions from '../../actions/payouts';
import myBetsActions from '../../actions/myBets';
import { isBackAndLaySelector, } from '../../selectors/game';

const { PLAYER_A, PLAYER_B, } = MAIN_BETS;
const { ONE_PAIR, STRAIGHT_FLUSH, THREE_OF_A_KIND, STRAIGHT, FLUSH, } = SIDEBETS;
const { PLAYER_A_BACK, PLAYER_B_BACK, PLAYER_A_LAY, PLAYER_B_LAY, } = BACK_LAY_BETS;

const {
  dialogActions: { dialog, },
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  settingsActions: { settings, },
  betActions: { history, totalBet, },
} = bootstrapActions;

const [ err, noWelcomeDialog, ] = ls.get(NO_WELCOME_DIALOG);

export default function handleInitialData(socketMessage, state) {
  const {
    VideoStreamData,
    roundId,
    timeStamp,
    RoundTripStartTime,
    playerACards,
    playerBCards,
    regularBets,
    sideBets,
    Statistics,
    payouts,
  } = socketMessage;

  const allCurrBets = [ ...regularBets, ...sideBets, ];
  const currBetsPayload = allCurrBets.reduce((acc, { type, betAmount, status, approved, step, }) => ({
    ...acc,
    [type]: {
      value: propOr(0, 'value', acc[type]) + betAmount,
      valid: approved || status === 'APPROVED',
      step,
    },
  }), {});

  // TODO: move totals computations to a dedicated epic
  // TODO: switch below logic to use a more general factory fn
  const getCurrBetValue = (bet) => +(propOr(0, 'value', currBetsPayload[bet])).toFixed(2);
  const totalsObj = isBackAndLaySelector(state)
    ? {
      // BACK BETS
      [BL_TOTALS.TOTAL_PLAYER_A_BACK]: getCurrBetValue(PLAYER_A_BACK),
      [BL_TOTALS.TOTAL_PLAYER_B_BACK]: getCurrBetValue(PLAYER_B_BACK),
      // LAY BETS
      [BL_TOTALS.TOTAL_PLAYER_A_LAY]: getCurrBetValue(PLAYER_A_LAY),
      [BL_TOTALS.TOTAL_PLAYER_B_LAY]: getCurrBetValue(PLAYER_B_LAY),
    }
    : {
      // MAIN BETS
      [TOTALS.TOTAL_PLAYER_A]: getCurrBetValue(PLAYER_A),
      [TOTALS.TOTAL_PLAYER_B]: getCurrBetValue(PLAYER_B),
      // SIDEBETS
      [TOTALS.TOTAL_ONE_PAIR]: getCurrBetValue(ONE_PAIR),
      [TOTALS.TOTAL_STRAIGHT]: getCurrBetValue(THREE_OF_A_KIND),
      [TOTALS.TOTAL_THREE_OF_A_KIND]: getCurrBetValue(STRAIGHT),
      [TOTALS.TOTAL_FLUSH]: getCurrBetValue(FLUSH),
      [TOTALS.TOTAL_STRAIGHT_FLUSH]: getCurrBetValue(STRAIGHT_FLUSH),
    };

  return of(
    socket.success(socketMessage),
    settings.init(),
    payoutsActions.payouts.set({ payouts, }),
    video.set({ streamData: VideoStreamData, }),
    statisticsActions.statistics.set(Statistics),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
    }),
    myBetsActions.myBets.compute(allCurrBets),
    totalsActions.totals.set(totalsObj),
    totalBet.set({ value: pipe(values, sum)(totalsObj), }),
    // reset history to prevent sending bets to server again
    // because NO_MORE_BETS message comes after this actions
    history.reset(),
    cardsActions.cards.set({
      [PLAYER_A_CARD_HAND]: { cards: playerACards, },
      [PLAYER_B_CARD_HAND]: { cards: playerBCards, },
    }),
    ...(err || !noWelcomeDialog ? [ dialog.add({ name: WELCOME_DIALOG, }), ] : []),
  );
}
