import { createSelector, } from 'reselect';
import { propOr, } from 'ramda';
import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';

const {
  configSelector,
} = bootstrapSelectors;

export const payoutsProgressSelector = createSelector(
  configSelector,
  propOr({}, 'payoutsProgress')
);

export const currentPayoutsProgressSelector = createSelector(
  payoutsProgressSelector,
  propOr({}, 'current')
);

export const statusPayoutsProgressSelector = createSelector(
  payoutsProgressSelector,
  propOr({}, 'status')
);
