import { prop, propOr, } from 'ramda';
import { BETS_ORDERS, MAIN_BETS, SIDEBETS, } from '../../../constants/betTypes';

const PAYOUT_TYPE_MULTIPLIER = 'multiplier';
const PAYOUT_TYPE_FRACTIONAL = 'fraction';

export const ZERO_PAYOUT_VALUE = null;
export const PAYOUT_PROGRESS = {
  UP: 'UP',
  DOWN: 'DOWN',
  NONE: ZERO_PAYOUT_VALUE,
};

export const getStatus = propOr(PAYOUT_PROGRESS.NONE, 'status');
export const getPayout = prop('payout');

export const formatPayout = (value, type) => {
  if (!value) return ZERO_PAYOUT_VALUE;

  switch (type) {
  case PAYOUT_TYPE_MULTIPLIER:
    return `x${value + 1}`;
  case PAYOUT_TYPE_FRACTIONAL:
    return `${value}/1`;
  default:
    return `${value}:1`;
  }
};

export function formatPayouts(payoutsList = {}, format) {
  return {
    [MAIN_BETS.PLAYER_A]: {
      name: MAIN_BETS.PLAYER_A,
      index: BETS_ORDERS[MAIN_BETS.PLAYER_A],
      payout: formatPayout(payoutsList[MAIN_BETS.PLAYER_A], format),
    },
    [MAIN_BETS.PLAYER_B]: {
      name: MAIN_BETS.PLAYER_B,
      index: BETS_ORDERS[MAIN_BETS.PLAYER_B],
      payout: formatPayout(payoutsList[MAIN_BETS.PLAYER_B], format),
    },
    [SIDEBETS.ONE_PAIR]: {
      name: SIDEBETS.ONE_PAIR,
      index: BETS_ORDERS[SIDEBETS.ONE_PAIR],
      payout: formatPayout(payoutsList[SIDEBETS.ONE_PAIR], format),
    },
    [SIDEBETS.THREE_OF_A_KIND]: {
      name: SIDEBETS.THREE_OF_A_KIND,
      index: BETS_ORDERS[SIDEBETS.THREE_OF_A_KIND],
      payout: formatPayout(payoutsList[SIDEBETS.THREE_OF_A_KIND], format),
    },
    [SIDEBETS.STRAIGHT]: {
      name: SIDEBETS.STRAIGHT,
      index: BETS_ORDERS[SIDEBETS.STRAIGHT],
      payout: formatPayout(payoutsList[SIDEBETS.STRAIGHT], format),
    },
    [SIDEBETS.FLUSH]: {
      name: SIDEBETS.FLUSH,
      index: BETS_ORDERS[SIDEBETS.FLUSH],
      payout: formatPayout(payoutsList[SIDEBETS.FLUSH], format),
    },
    [SIDEBETS.STRAIGHT_FLUSH]: {
      name: SIDEBETS.STRAIGHT_FLUSH,
      index: BETS_ORDERS[SIDEBETS.STRAIGHT_FLUSH],
      payout: formatPayout(payoutsList[SIDEBETS.STRAIGHT_FLUSH], format),
    },
  };
}


export function getComputedPayoutsEvolution(prevPayouts = {}, nextPayouts = {},) {
  function comparePayouts(prev, next) {
    let status = PAYOUT_PROGRESS.NONE;
    if (!prev || next === prev) return PAYOUT_PROGRESS.NONE;
    status = (next > prev) ? PAYOUT_PROGRESS.UP : PAYOUT_PROGRESS.DOWN;
    return status;
  }

  return {
    [MAIN_BETS.PLAYER_A]: {
      name: MAIN_BETS.PLAYER_A,
      status: comparePayouts(prevPayouts[MAIN_BETS.PLAYER_A], nextPayouts[MAIN_BETS.PLAYER_A]),
      payout: nextPayouts[MAIN_BETS.PLAYER_A],
    },
    [MAIN_BETS.PLAYER_B]: {
      name: MAIN_BETS.PLAYER_B,
      status: comparePayouts(prevPayouts[MAIN_BETS.PLAYER_B], nextPayouts[MAIN_BETS.PLAYER_B]),
      payout: nextPayouts[MAIN_BETS.PLAYER_B],
    },
    [SIDEBETS.ONE_PAIR]: {
      name: SIDEBETS.ONE_PAIR,
      status: comparePayouts(prevPayouts[SIDEBETS.ONE_PAIR], nextPayouts[SIDEBETS.ONE_PAIR]),
      payout: nextPayouts[SIDEBETS.ONE_PAIR],
    },
    [SIDEBETS.THREE_OF_A_KIND]: {
      name: SIDEBETS.THREE_OF_A_KIND,
      status: comparePayouts(prevPayouts[SIDEBETS.THREE_OF_A_KIND], nextPayouts[SIDEBETS.THREE_OF_A_KIND]),
      payout: nextPayouts[SIDEBETS.THREE_OF_A_KIND],
    },
    [SIDEBETS.STRAIGHT]: {
      name: SIDEBETS.STRAIGHT,
      status: comparePayouts(prevPayouts[SIDEBETS.STRAIGHT], nextPayouts[SIDEBETS.STRAIGHT]),
      payout: nextPayouts[SIDEBETS.STRAIGHT],
    },
    [SIDEBETS.FLUSH]: {
      name: SIDEBETS.FLUSH,
      status: comparePayouts(prevPayouts[SIDEBETS.FLUSH], nextPayouts[SIDEBETS.FLUSH]),
      payout: nextPayouts[SIDEBETS.FLUSH],
    },
    [SIDEBETS.STRAIGHT_FLUSH]: {
      name: SIDEBETS.STRAIGHT_FLUSH,
      status: comparePayouts(prevPayouts[SIDEBETS.STRAIGHT_FLUSH], nextPayouts[SIDEBETS.STRAIGHT_FLUSH]),
      payout: nextPayouts[SIDEBETS.STRAIGHT_FLUSH],
    },
  };
}
