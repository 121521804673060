import { createSelector, } from 'reselect';
import {
  map, pipe, prop, propOr, reject,
} from 'ramda';

import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';

import { BACK_LAY_BETS, BL_TOTALS, } from '../../../constants/BackAndLay/betTypes';
import { currentBetsSelector, totalsSelector, } from '../bets';

const { chipsSelector, validatedChipsSelector, payoutsSelector, userBalanceSelector, } = bootstrapSelectors;

const { TOTAL_PLAYER_A_BACK, TOTAL_PLAYER_B_BACK, TOTAL_PLAYER_A_LAY, TOTAL_PLAYER_B_LAY, } = BL_TOTALS;
const { PLAYER_A_BACK, PLAYER_B_BACK, PLAYER_A_LAY, PLAYER_B_LAY, } = BACK_LAY_BETS;

export const backBetValuesSelector = createSelector(
  validatedChipsSelector,
  pipe(
    reject(prop('disabled')),
    map(prop('value')),
  ),
);

export const playerALayBetValuesSelector = createSelector(
  chipsSelector,
  payoutsSelector,
  userBalanceSelector,
  pipe(
    (chips, payouts, balance) => reject(
      ({ value, }) => balance < value * payouts[PLAYER_A_LAY].value,
      chips
    ),
    map(prop('value')),
  ),
);

export const playerBLayBetValuesSelector = createSelector(
  chipsSelector,
  payoutsSelector,
  userBalanceSelector,
  pipe(
    (chips, payouts, balance) => reject(
      ({ value, }) => balance < value * payouts[PLAYER_B_LAY].value,
      chips
    ),
    map(prop('value')),
  ),
);

export const playerABackSelector = createSelector(
  currentBetsSelector,
  prop(PLAYER_A_BACK)
);

export const totalPlayerABackSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_PLAYER_A_BACK)
);


export const playerBBackSelector = createSelector(
  currentBetsSelector,
  prop(PLAYER_B_BACK)
);

export const totalPlayerBBackSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_PLAYER_B_BACK)
);

export const playerALaySelector = createSelector(
  currentBetsSelector,
  prop(PLAYER_A_LAY)
);

export const totalPlayerALaySelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_PLAYER_A_LAY)
);

export const playerBLaySelector = createSelector(
  currentBetsSelector,
  prop(PLAYER_B_LAY)
);

export const totalPlayerBLaySelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_PLAYER_B_LAY)
);
