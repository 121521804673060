import { of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import * as BET_TYPES from '../../../constants/betTypes';

import { gameResultsSelector, } from '../../selectors/game';

const { MAIN_BETS: { PLAYER_A, PLAYER_B, }, } = BET_TYPES;

const { gameActions: { game, }, } = bootstrapActions;

export default function handleWonBets({ WinningBets, }, state) {
  const wonSidebets = Object.entries(WinningBets).reduce(
    (acc, [ betType, value, ]) => ([ PLAYER_A, PLAYER_B, ].includes(betType)
      ? acc
      : [ ...acc, { betType, value, }, ]),
    []
  );

  return of(game.set({
    gameResults: {
      ...gameResultsSelector(state),
      wonSidebets,
      winningBets: WinningBets,
    },
  }));
}
