import bowser from 'bowser';
import qs from 'qs';

export const gameIdFromURL = Number(qs.parse(location.search, { ignoreQueryPrefix: true, }).game_id);

export const isBackAndLayOnURL = gameIdFromURL === 50;

export const isHandheld = bowser.mobile || bowser.tablet;

export const notNil = (x) => x != null;
