import { createSelector, } from 'reselect';
import { length, prop, sum, } from 'ramda';
import { MAX_CARDS_NUMBER, PLAYER_A_CARD_HAND, PLAYER_B_CARD_HAND, } from '../../constants/cards';

export const cardsSelector = prop('cards');

export const playerACardsSelector = createSelector(
  cardsSelector,
  prop(PLAYER_A_CARD_HAND)
);

export const playerBCardsSelector = createSelector(
  cardsSelector,
  prop(PLAYER_B_CARD_HAND)
);

export const numberOfCardsSelector = createSelector(
  playerACardsSelector,
  playerBCardsSelector,
  (playerACards, playerBCards) => sum([ length(playerACards.cards), length(playerBCards.cards), ]) || 0
);

export const hasReachedCardsLimitSelector = createSelector(
  numberOfCardsSelector,
  (nrOfCards) => nrOfCards === MAX_CARDS_NUMBER
);
