import { flatMap, pluck, } from 'rxjs/operators';
import { of, } from 'rxjs';
import { combineEpics, ofType, } from 'redux-observable';
import { map, } from 'ramda';

import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import { LAY, } from '@ezugi/constants';

import myBetsActions from '../../actions/myBets';
import { formatPayout, } from '../payouts/utils';
import { formatBLPayout, } from '../BackAndLay/utils';

import { numberOfCardsSelector, } from '../../selectors/cards';
import { computeMyBetsSelector, } from '../../selectors/bets';
import { isBackAndLaySelector, } from '../../selectors/game';

import { BETS_ORDERS, } from '../../../constants/betTypes';
import { REVERSE_BET_TYPES, } from '../../../constants/BackAndLay/betTypes';

const {
  formatSelector,
} = bootstrapSelectors;
const {
  myBets,
} = myBetsActions;

// const getValue = ({ betType, payout, betAmount, state, }) => {
//   const isLayBet = REVERSE_BET_TYPES[betType].betSide === LAY;
//   return isBackAndLaySelector(state) && isLayBet ? (betAmount / payout).toFixed() : betAmount;
// };
// const getPayout = ({ payout, state, }) => {
//   const { payoutFormat, } = formatSelector(state);
//   return isBackAndLaySelector(state)
//     ? formatBLPayout(payout, payoutFormat) : formatPayout(payout, payoutFormat);
// };
const getProfit = ({ betType, payout, betAmount, state, }) => {
  const isLayBet = REVERSE_BET_TYPES[betType].betSide === LAY;
  return isBackAndLaySelector(state)
    ? (isLayBet ? +((1 + payout) * (betAmount / payout)).toFixed(2) : +((1 + payout) * betAmount).toFixed(2))
    : undefined;
};
const getPropsByGameType = ({ betType, payout, betAmount, state, }) => {
  const isLayBet = REVERSE_BET_TYPES[betType].betSide === LAY;
  const { payoutFormat, } = formatSelector(state);

  const profit = getProfit({ betType, payout, betAmount, state, });
  const value = isBackAndLaySelector(state) && isLayBet ? (betAmount / payout).toFixed() : betAmount;
  const formattedPayout = isBackAndLaySelector(state)
    ? formatBLPayout(payout, payoutFormat) : formatPayout(payout, payoutFormat);

  return { profit, value, payout: formattedPayout, };
};


const myBetsCacheEpic = (action$, state$) => action$.pipe(
  ofType(myBets.cache),
  pluck('payload'),
  flatMap(() => {
    const myBetsObj = computeMyBetsSelector(state$.value);
    const myBetsWithProfits = map((obj) => ({
      ...obj,
      profit: getProfit({ betType: obj.name, payout: obj.payout, betAmount: obj.value, state: state$.value, }),
    }), myBetsObj);
    const nbCards = numberOfCardsSelector(state$.value);
    return of(myBets.push({ [nbCards + 1]: myBetsWithProfits, }));
  })
);

const myBetsComputeEpic = (action$, state$) => action$.pipe(
  ofType(myBets.compute),
  pluck('payload'),
  flatMap((betsPayload = []) => {
    const myBetsObj = betsPayload.reduce((acc, { betAmount, step, type, status, approved, ...values }) => {
      acc[step + 1] = { ...acc[step + 1],
        [type]: {
          ...values,
          // payout: getPayout({ payout: values.payout, state: state$.value, }),
          name: type,
          // value: getValue({ betType: type, payout: values.payout, betAmount, state: state$.value, }),
          // profit: getProfit({ betType: type, payout: values.payout, betAmount, state: state$.value, }),
          valid: approved || status === 'APPROVED',
          index: BETS_ORDERS[type],
          ...getPropsByGameType({ betType: type, payout: values.payout, betAmount, state: state$.value, }),
        }, };
      return acc;
    }, {});

    return of(myBets.push(myBetsObj));
  })
);

export default combineEpics(
  myBetsCacheEpic,
  myBetsComputeEpic,
);
