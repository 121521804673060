import { mapObjIndexed, pick, values, } from 'ramda';
import * as BET_TYPES from '../../../../constants/BackAndLay/betTypes';

export const createBackAndLayBetsList = (bets) => {
  const BACK_BETS = Object.values(BET_TYPES.BACK_BETS);
  const LAY_BETS = Object.values(BET_TYPES.LAY_BETS);

  const seed = mapObjIndexed(({ value, }) => value, bets);
  const backBets = pick(values(BACK_BETS), seed);
  const layBets = pick(values(LAY_BETS), seed);
  const betsObj = {
    betsList: { ...backBets, ...layBets, },
    sideBets: {},
  };

  return betsObj;
};
