import {
  distinctUntilChanged, filter, flatMap, map, pluck, skipUntil, take,
} from 'rxjs/operators';
import { combineEpics, ofType, } from 'redux-observable';
import { actions as bootstrapActions, selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import {
  equals, isEmpty, length, map as rMap, pipe, propOr, sum, values,
} from 'ramda';
import { concat, of, } from 'rxjs';
import { NO_MORE_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';
import { formatBLPayouts, } from './utils';
import totalsActions from '../../actions/totals';
import payoutsActions from '../../actions/payouts';
import { validateBet, } from './bets/validations';
import { createPlaceBetRequestPayload, } from '../bets/utils/request';
import { createBackAndLayBetsList, } from './bets/request';
import { hasReachedCardsLimitSelector, } from '../../selectors/cards';
import {
  playerABackSelector,
  playerALaySelector,
  playerBBackSelector,
  playerBLaySelector,
  totalPlayerABackSelector,
  totalPlayerALaySelector,
  totalPlayerBBackSelector,
  totalPlayerBLaySelector,
} from '../../selectors/BackAndLay';
import { currentBetsSelector, } from '../../selectors/bets';
import { getTotalBet, } from '../bets/utils';
import myBetsActions from '../../actions/myBets';
import { BL_TOTALS, } from '../../../constants/BackAndLay/betTypes';


const { totals, } = totalsActions;
const {
  configActions: { config, },
  roundActions: { round, },
  betActions: { bet, totalBet, history, },
} = bootstrapActions;
const {
  formatSelector,
} = bootstrapSelectors;

/* PAYOUTS EPICS */

function payoutsEpic(action$, state$) {
  return action$.pipe(
    ofType(payoutsActions.payouts.set),
    pluck('payload'),
    map(({ payouts: nextPayoutsValues = {}, }) => {
      const { payoutFormat: PAYOUT_FORMAT, } = formatSelector(state$.value);

      return config.update({
        payouts: formatBLPayouts(nextPayoutsValues, PAYOUT_FORMAT),
      });
    }),
  );
}

/* BETS EPICS */

const betRequestEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  distinctUntilChanged(),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(round.set),
      pluck('payload', 'roundStatus'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  map(() => createPlaceBetRequestPayload(state$.value, createBackAndLayBetsList)),
  filter(length),
  flatMap((_actions) => concat(...rMap(of, _actions), of(bet.success())))
);

const placeBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.request),
  pluck('payload'),
  // TODO: remove below debug line
  // tap((payload) => { console.log('placeBetEpic', { payload, }); return payload; }),
  map((bets) => validateBet(bets, state$.value)),
  flatMap(({ valid, bet: _bet, ...result }) => {
    const actions = (valid && _bet && !isEmpty(_bet)) ? [ of(bet.set(_bet)), ] : [];
    actions.push(...rMap(of, result.actions));
    // if (!isEmpty(actions)) actions.push(of(myBetsActions.myBets.cache()));

    return concat(...actions);
  })
);

const betResetEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS && hasReachedCardsLimitSelector(state$.value)),
  flatMap(() => concat(of(bet.reset()), of(history.reset())))
);

const totalBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.set),
  map(() => {
    const currentBets = currentBetsSelector(state$.value);

    return totalBet.set({ value: getTotalBet(currentBets), });
  })
);

const totalsForAllBetsEpic = (action$, state$) => action$.pipe(
  ofType(bet.success),
  flatMap(() => {
    const calcTotalValue = (betTotalSelector, currBetValueSelector) => (
      betTotalSelector(state$.value) + propOr(0, 'value', currBetValueSelector(state$.value))
    );

    const totalsObj = {
      // TOTALS FOR BACK BETS
      [BL_TOTALS.TOTAL_PLAYER_A_BACK]: calcTotalValue(totalPlayerABackSelector, playerABackSelector),
      [BL_TOTALS.TOTAL_PLAYER_B_BACK]: calcTotalValue(totalPlayerBBackSelector, playerBBackSelector),
      // TOTALS FOR LAY BETS
      [BL_TOTALS.TOTAL_PLAYER_A_LAY]: calcTotalValue(totalPlayerALaySelector, playerALaySelector),
      [BL_TOTALS.TOTAL_PLAYER_B_LAY]: calcTotalValue(totalPlayerBLaySelector, playerBLaySelector),
    };

    return of(
      myBetsActions.myBets.cache(),
      totals.set(totalsObj),
      bet.clear(),
      totalBet.set({ value: pipe(values, sum)(totalsObj), }),
    );
  }),
);


/* MY BETS EPIC */
// TODO: add MY BETS epic


export default combineEpics(
  payoutsEpic,
  placeBetEpic,
  totalBetEpic,
  betResetEpic,
  betRequestEpic,
  totalsForAllBetsEpic,
);
