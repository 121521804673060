import styled, { css, keyframes, } from 'react-emotion';
import tinycolor from 'tinycolor2';
import { theme, } from '@ezugi/bootstrap';

import * as BET_TYPES from '../../../constants/betTypes';
import { OPACITY_HIGH, } from '../../../constants/colors';
import { SECTION_HEADER_HEIGHT, PROGRESS_BAR, } from './constants';

const { palette, } = theme;

const {
  MAIN_BETS: { PLAYER_A, PLAYER_B, },
} = BET_TYPES;

export const GAME_BOARD_PERCENTAGE_HEIGHT = 24; // px

export const SectionHeader = styled.header`
    height: ${SECTION_HEADER_HEIGHT}px;
    position: relative;
    color: ${palette.foreground};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
`;

const highlightKeyframe = keyframes`
  from {
    fill-opacity: 0.65;
  }
  to {
    fill-opacity: 1;
  }
`;

export const Section = styled.section`
  height: 100%;
  top: 0;
  display: inline-flex;
  flex-flow: column nowrap;
  vertical-align: top;
  position: relative;
  transition: flex 0.27s linear, height 0.27s linear;
  transform-origin: bottom center;
  flex: 1;
  background: ${palette.primary};

  > svg {
    overflow: visible;
  }

  // TODO: check this code below is working on GameResults
  // no-winning-bets added to keep css valid when no winning bets
  ${({ winningBets = [], }) => winningBets.map((bet) => `#bet-${bet}`).join(',') || 'no-winning-bets'} {
    .highlight {
      animation: ${highlightKeyframe} 0.35s 0.1s infinite alternate ease-in-out;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 23%;
  bottom: 0;
  left: 0;
  pointer-events: all;
  user-select: none;
  background: transparent;
  flex-flow: row nowrap;
  transform: translateY(0);
  transition: transform 0.2s linear;

  #bet-icon {
    pointer-events: all;
  }

  [id^='bet-'] {
    &:hover,
    &:active {
      cursor: pointer;
      .highlight {
        fill-opacity: ${OPACITY_HIGH};
      }
    }
  }
`;

const highlightClassName = css`
  .highlight {
    animation: ${highlightKeyframe} infinite .5s 0.1s alternate ease-in-out;
  }
`;

export const mainBetsHighlight = css` // TODO: highlight BACK & LAY bets on GAME RESULT
  #bet-${PLAYER_A}, #bet-${PLAYER_B} {
    ${highlightClassName}
  }
`;

export const inactiveGrid = css`
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.9;
`;

export const centerSectionClassName = css`
  align-items: center;
  flex-flow: column nowrap;
  flex: 1.9;
  border: 4px solid ${palette.primary};
  border-radius: ${PROGRESS_BAR.BORDER_RADIUS}px ${PROGRESS_BAR.BORDER_RADIUS}px 0 0;
`;

export const mobileCenterSectionClassName = css`
  border: 0px;
  @media all and (orientation: landscape) {
    padding: 0 4px 2px 4px;
    flex: 1;
  }
  @media all and (orientation: portrait) {
    height: auto;
    flex-grow: 0;
    flex-basis: auto;
  }
`;

export const desktopBetGridClassName = css`
  padding: 0;
  height: 100%;
  width: 97%;
`;

export const progressBarClassName = css`
  height: ${PROGRESS_BAR.HEIGHT}px;
  width: 100%;
  border-radius: ${PROGRESS_BAR.BORDER_RADIUS}px;
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
`;

export const mobileContainerClassName = css`
  flex-flow: column nowrap;
  height: 311px;

  [data-name="center-section"] {
    background: transparent;
    order: 0;
  }
  [data-name="left-section"] {
    order: 1;
  }
  [data-name="right-section"] {
    order: 2;
  }

  @media all and (orientation: landscape) {
    padding: 0;
    height: auto;
  }
  @media all and (orientation: portrait) {
    background-color: var(--background-color, ${palette.primary});
    padding: 0 4px;
  }
`;

export const mobileBetsClosedClassName = css`
  @media all and (orientation: landscape) {
    perspective: 400px;
    margin: 0;
    [data-name="center-section"] {
      transform: rotateX(55deg);
    }
  }
`;

export const smallSectionStyle = css`
  padding 4px 4px 0 4px;
  background: ${tinycolor(palette.primary).setAlpha(0.7).toString()};
  height: calc(100% - ${SECTION_HEADER_HEIGHT}px);
  top: ${SECTION_HEADER_HEIGHT}px;
`;

export const mobileRightSection = css`
  @media all and (orientation: portrait) {
    height: auto;
    flex-grow: 0;
    flex-basis: auto;
  }
`;

export const mobileLeftSection = css`
  @media all and (orientation: portrait) {
    &:empty {
      display: none;
    }
  }
`;

// export const sidebetsClassName = css`
//   padding: 6px 0;
//   height: 64px;
// `;

// export const sidebetsNMBClassName = css`
//   padding: 4px 0;
//   height: 42px;
// `;
