import {
  defaultTo, filter, isEmpty, pipe,
} from 'ramda';
import { concat, of, } from 'rxjs';

import { actions as bootstrapActions, } from '@ezugi/bootstrap';

const {
  notificationActions: { notification, },
  betActions,
} = bootstrapActions;


const handleOperatorError = (socketMessage) => {
  const { BetsList, } = socketMessage;

  const validBetsList = pipe(
    defaultTo([]),
    filter((bet) => bet.status === 'APPROVED'),
  )(BetsList);

  return concat(of(
    ...(isEmpty(validBetsList)
      ? [ betActions.bet.reset(), ]
      : [
        betActions.history.pop(),
        betActions.bet.apply({ current: {}, }),
      ]),
    notification.add({ message: 'messages.bet_too_late', })
  ));
};

export default handleOperatorError;
