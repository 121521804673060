import { createSelector, } from 'reselect';
import { pathOr, propOr, } from 'ramda';

import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import * as BET_TYPES from '../../constants/betTypes';
import { PUSH, WIN_PLAYER_A, } from '../../components/ResultDisplay/constants';

const { gameIdSelector, configSelector, } = bootstrapSelectors;

const { MAIN_BETS: { PLAYER_A, PLAYER_B, }, } = BET_TYPES;

export const gameResultsSelector = pathOr({}, [ 'game', 'gameResults', ]);

export const statisticsSelector = propOr({}, 'statistics');

export const lastWinnersSelector = createSelector(
  statisticsSelector,
  propOr([], 'lastWinners')
);

export const winningMainBetsSelector = createSelector(
  gameResultsSelector,
  (gameResults) => {
    const { WinningHand, } = gameResults;

    return WinningHand !== PUSH ? [ WinningHand === WIN_PLAYER_A ? PLAYER_A : PLAYER_B, ] : [];
  }
);

export const winningSideBetsSelector = createSelector(
  gameResultsSelector,
  (gameResults) => {
    const { SideBetsResult = [], } = gameResults;

    const bets = SideBetsResult.reduce((acc, curr) => {
      if (curr.HasSideBetWon) {
        return [ ...acc, curr.betType, ];
      }

      return acc;
    }, []);

    return bets;
  }
);

export const winningBetsSelector = createSelector(
  winningMainBetsSelector,
  winningSideBetsSelector,
  (winningMainBets, winningSideBets) => winningMainBets.concat(winningSideBets)
);

export const isBackAndLaySelector = createSelector(
  gameIdSelector,
  configSelector,
  (gameId, config) => Number(gameId) === 50 || propOr(false, 'isBackAndLayTable', config)
);
