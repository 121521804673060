import { of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { BACK_LAY_BETS, } from '../../../../constants/BackAndLay/betTypes';

import { gameResultsSelector, } from '../../../selectors/game';

const { PLAYER_A_BACK, PLAYER_A_LAY, PLAYER_B_BACK, PLAYER_B_LAY, } = BACK_LAY_BETS;

const { gameActions: { game, }, } = bootstrapActions;

const BL_ODTP_BETS = [ PLAYER_A_BACK, PLAYER_A_LAY, PLAYER_B_BACK, PLAYER_B_LAY, ];

export default function handleBLWonBets({ WinningBets, }, state) {
  const wonSidebets = Object.entries(WinningBets).reduce(
    (acc, [ betType, value, ]) => (BL_ODTP_BETS.includes(betType)
      ? acc
      : [ ...acc, { betType, value, }, ]),
    []
  );

  return of(game.set({
    gameResults: {
      ...gameResultsSelector(state),
      wonSidebets,
      winningBets: WinningBets,
    },
  }));
}
