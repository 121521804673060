import { BACK, LAY, ONE_DAY_TEEN_PATTI_BET_TYPES, } from '@ezugi/constants';

const { PLAYER_A, PLAYER_B, PLAYER_A_BACK, PLAYER_B_BACK, PLAYER_A_LAY, PLAYER_B_LAY, } = ONE_DAY_TEEN_PATTI_BET_TYPES;

export const BACK_BETS = { PLAYER_A_BACK, PLAYER_B_BACK, };
export const LAY_BETS = { PLAYER_A_LAY, PLAYER_B_LAY, };
export const BACK_LAY_BETS = { PLAYER_A_BACK, PLAYER_B_BACK, PLAYER_A_LAY, PLAYER_B_LAY, };
export const BET_SIDES = { BACK, LAY, };

export const BET_TYPES = {
  [PLAYER_A]: {
    [BACK]: PLAYER_A_BACK,
    [LAY]: PLAYER_A_LAY,
  },
  [PLAYER_B]: {
    [BACK]: PLAYER_B_BACK,
    [LAY]: PLAYER_B_LAY,
  },
};

export const REVERSE_BET_TYPES = {
  [PLAYER_A_BACK]: {
    betName: PLAYER_A,
    betSide: BACK,
  },
  [PLAYER_B_BACK]: {
    betName: PLAYER_B,
    betSide: BACK,
  },
  [PLAYER_A_LAY]: {
    betName: PLAYER_A,
    betSide: LAY,
  },
  [PLAYER_B_LAY]: {
    betName: PLAYER_B,
    betSide: LAY,
  },
};

export const BL_BETS_ORDERS = {
  [PLAYER_A_BACK]: 0,
  [PLAYER_B_BACK]: 1,
  [PLAYER_A_LAY]: 2,
  [PLAYER_B_LAY]: 3,
};

export const BL_TOTALS = {
  // BACK BETS
  TOTAL_PLAYER_A_BACK: `total${PLAYER_A_BACK}`,
  TOTAL_PLAYER_B_BACK: `total${PLAYER_B_BACK}`,
  // LAY BETS
  TOTAL_PLAYER_A_LAY: `total${PLAYER_A_LAY}`,
  TOTAL_PLAYER_B_LAY: `total${PLAYER_B_LAY}`,
};
