import styled from 'react-emotion';
import tinycolor from 'tinycolor2';

import { theme, } from '@ezugi/bootstrap';

const { palette, tints, } = theme;

const BORDER_COLOR = tinycolor(palette.foreground)
  .setAlpha(tints.divider)
  .toString();

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 35px;
`;

export const Label = styled.div`
  background: ${tinycolor(palette.primary).setAlpha(0.8).toString()};
  color: ${palette.primaryContrast};
  padding: 8px 60px 8px 60px;
  font-size: 22px;
  line-height: 18px;
  font-family: 'Roboto';
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
`;
