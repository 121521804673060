import { propOr, } from 'ramda';
import { BACK_BETS, BL_BETS_ORDERS, LAY_BETS, } from '../../../constants/BackAndLay/betTypes';

export const BL_ZERO_PAYOUT_VALUE = 0;

export const formatBLPayout = (value) => String(value);
export const getBLFormattedPayout = propOr(`${BL_ZERO_PAYOUT_VALUE}`, 'payout');
export const getBLPayoutValue = propOr(BL_ZERO_PAYOUT_VALUE, 'value');


export function formatBLPayouts(payoutsList = {}, format) {
  return {
    [BACK_BETS.PLAYER_A_BACK]: {
      name: BACK_BETS.PLAYER_A_BACK,
      index: BL_BETS_ORDERS[BACK_BETS.PLAYER_A_BACK],
      payout: formatBLPayout(payoutsList[BACK_BETS.PLAYER_A_BACK], format),
      value: payoutsList[BACK_BETS.PLAYER_A_BACK],
    },
    [BACK_BETS.PLAYER_B_BACK]: {
      name: BACK_BETS.PLAYER_B_BACK,
      index: BL_BETS_ORDERS[BACK_BETS.PLAYER_B_BACK],
      payout: formatBLPayout(payoutsList[BACK_BETS.PLAYER_B_BACK], format),
      value: payoutsList[BACK_BETS.PLAYER_B_BACK],
    },
    [LAY_BETS.PLAYER_A_LAY]: {
      name: LAY_BETS.PLAYER_A_LAY,
      index: BL_BETS_ORDERS[LAY_BETS.PLAYER_A_LAY],
      payout: formatBLPayout(payoutsList[LAY_BETS.PLAYER_A_LAY], format),
      value: payoutsList[LAY_BETS.PLAYER_A_LAY],
    },
    [LAY_BETS.PLAYER_B_LAY]: {
      name: LAY_BETS.PLAYER_B_LAY,
      index: BL_BETS_ORDERS[LAY_BETS.PLAYER_B_LAY],
      payout: formatBLPayout(payoutsList[LAY_BETS.PLAYER_B_LAY], format),
      value: payoutsList[LAY_BETS.PLAYER_B_LAY],
    },
  };
}
