import { concat, EMPTY, of, } from 'rxjs';
import { actions as bootstrapActions, selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import { totalsValueSelector, } from '../../selectors/bets';

const {
  betActions: { totalBet, },
} = bootstrapActions;

const {
  totalBetSelector,
} = bootstrapSelectors;

export default function handleNoMoreBets(socketMessage, state) {
  const currentTotalBet = totalBetSelector(state);

  // update totalBet with prev round step bets (there are no currentBets) otherwise it will update on bet.success() case
  return !currentTotalBet
    ? concat(of(
      totalBet.set({ value: totalsValueSelector(state), }),
    ))
    : EMPTY;
}
